/* eslint-disable @typescript-eslint/no-explicit-any */
import supabaseClient from "../../../common/supabaseClient";
import { supabaseRequest } from "./constants";
import { Appointment } from "./types";

interface GetAppointmentsParams {
  customerId: string;
  ignoreStatuses?: Array<string>;
  isCheckInApp: boolean;
}

const getAppointments = async (params: GetAppointmentsParams) => {
  const { customerId, ignoreStatuses, isCheckInApp } = params;

  let query = supabaseClient
    .from<Appointment>("appointments")
    .select(supabaseRequest)
    .eq("appointment_customer_details.customer.id" as any, customerId)
    .is("appointment_offerings.appointment_package_id" as any, null)
    .is("appointment_add_ons.appointment_package_id" as any, null)
    .is("appointment_add_ons.appointment_offering_id" as any, null);

  if (!!ignoreStatuses && !!ignoreStatuses.length) {
    query = query.not("status", "in", `(${ignoreStatuses})`);
  }
  if (isCheckInApp) {
    query = query.in("status", ["confirmed", "intake_complete"]);
  }

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default getAppointments;
