import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      titleInformationRequired: formatMessage(
        messages.titleInformationRequired,
      ),
      descriptionInformationRequired: formatMessage(
        messages.descriptionInformationRequired,
      ),
      btnEditPatient: formatMessage(messages.btnEditPatient),
      btnPatientInformation: formatMessage(messages.btnPatientInformation),
      btnFormPrivacy: formatMessage(messages.btnFormPrivacy),
      btnFormConsent: formatMessage(messages.btnFormConsent),
      btnFormScreening: formatMessage(messages.btnFormScreening),
      btnDownloadReport: formatMessage(messages.btnDownloadReport),
      btnRequestImages: formatMessage(messages.btnRequestImages),
      for: formatMessage(messages.for),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
